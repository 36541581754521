var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.loadingData)?_c('optix-header',[_vm._v("\n    "+_vm._s(_vm.isInquiryMode
        ? "Additional information"
        : "Provide additional information")+"\n  ")]):_vm._e(),_c('optix-container',[(_vm.loadingData)?_c('optix-content-layout',[_c('optix-loading')],1):_c('optix-content-layout',[_c('optix-form',{ref:"form"},[(!_vm.isInquiryMode)?_c('p',{staticClass:"mb-5"},[_vm._v("\n          This information is for your venue manager only and will not display\n          on your in-app community profile. You can update this information\n          later.\n        ")]):_vm._e(),(_vm.isAvailable('phone'))?_c('optix-text-field',{attrs:{"label":'Phone number' + (_vm.isRequired('phone') ? '*' : ''),"type":"tel","error-messages":_vm.errors.phone},model:{value:(_vm.$optixConfiguration.user.data.phone),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.user.data, "phone", $$v)},expression:"$optixConfiguration.user.data.phone"}}):_vm._e(),(
            _vm.isAvailable('primary_location') && !_vm.isProfile('primary_location')
          )?_c('optix-select',{attrs:{"label":'Primary location' + (_vm.isRequired('primary_location') ? '*' : ''),"type":"tel","items":_vm.availablePrimaryLocations,"item-text":"name","item-value":"location_id","hint":"Choose where you will spend most of your time","persistent-hint":"","error-messages":_vm.errors.primary_location_id},model:{value:(_vm.$optixConfiguration.member.data.primary_location_id),callback:function ($$v) {_vm.$set(_vm.$optixConfiguration.member.data, "primary_location_id", $$v)},expression:"$optixConfiguration.member.data.primary_location_id"}}):_vm._e(),_vm._l((_vm.property_groups),function(property_group){return _c('div',{key:property_group.property_group_id,staticClass:"my-2"},[_c('h4',{staticClass:"my-4"},[_vm._v(_vm._s(property_group.label))]),_vm._l((_vm.property_fields[
              property_group.property_group_id
            ]),function(property_field){return _c('optix-property-field',{key:property_field.property_id,ref:`prop${property_field.property_id}`,refInFor:true,staticClass:"my-3",attrs:{"config":property_field},model:{value:(
              _vm.property_values[property_group.property_group_id][
                property_field.property_id
              ]
            ),callback:function ($$v) {_vm.$set(_vm.property_values[property_group.property_group_id], 
                property_field.property_id
              , $$v)},expression:"\n              property_values[property_group.property_group_id][\n                property_field.property_id\n              ]\n            "}})})],2)})],2)],1)],1),_c('optix-footer-navigation',[_c('optix-btn',{attrs:{"slot":"left","flat":""},on:{"click":_vm.back},slot:"left"},[_vm._v("Back")]),_c('optix-dot-progress',{attrs:{"slot":"center"},slot:"center"}),_c('optix-btn',{attrs:{"slot":"right","loading":_vm.nextLoading,"disabledStyle":_vm.loadingData},on:{"click":_vm.next},slot:"right"},[_vm._v("Next")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }