<template>
  <div>
    <optix-header v-if="!loadingData">
      {{
        isInquiryMode
          ? "Additional information"
          : "Provide additional information"
      }}
    </optix-header>
    <optix-container>
      <optix-content-layout v-if="loadingData">
        <optix-loading />
      </optix-content-layout>
      <optix-content-layout v-else>
        <optix-form ref="form">
          <p v-if="!isInquiryMode" class="mb-5">
            This information is for your venue manager only and will not display
            on your in-app community profile. You can update this information
            later.
          </p>

          <optix-text-field
            v-if="isAvailable('phone')"
            :label="'Phone number' + (isRequired('phone') ? '*' : '')"
            type="tel"
            v-model="$optixConfiguration.user.data.phone"
            :error-messages="errors.phone"
          />

          <optix-select
            v-if="
              isAvailable('primary_location') && !isProfile('primary_location')
            "
            :label="
              'Primary location' + (isRequired('primary_location') ? '*' : '')
            "
            type="tel"
            :items="availablePrimaryLocations"
            item-text="name"
            item-value="location_id"
            v-model="$optixConfiguration.member.data.primary_location_id"
            hint="Choose where you will spend most of your time"
            persistent-hint
            :error-messages="errors.primary_location_id"
          />

          <div
            v-for="property_group in property_groups"
            :key="property_group.property_group_id"
            class="my-2"
          >
            <h4 class="my-4">{{ property_group.label }}</h4>
            <optix-property-field
              :ref="`prop${property_field.property_id}`"
              class="my-3"
              v-for="property_field in property_fields[
                property_group.property_group_id
              ]"
              v-model="
                property_values[property_group.property_group_id][
                  property_field.property_id
                ]
              "
              :config="property_field"
              :key="property_field.property_id"
            />
          </div>
        </optix-form>
      </optix-content-layout>
    </optix-container>
    <optix-footer-navigation>
      <optix-btn slot="left" flat @click="back">Back</optix-btn>
      <optix-dot-progress slot="center" />
      <optix-btn
        slot="right"
        @click="next"
        :loading="nextLoading"
        :disabledStyle="loadingData"
        >Next</optix-btn
      >
    </optix-footer-navigation>
  </div>
</template>
<script>
import Validate from "@/mixins/Validate.js";
import Steps from "@/mixins/Steps.js";
import forEach from "lodash/forEach";

export default {
  mixins: [Validate, Steps],
  data() {
    return {
      loadingData: true,
      nextLoading: false,
      profile_fields: {},
      property_groups: [],
      property_fields: {},
      property_values: {},
      possible_options: ["phone"],
    };
  },
  mounted() {
    if (!this.$optixConfiguration.user.lookup.member_account) {
      this.$router.push({ name: "Lookup" });
      return;
    }

    let venue_profile_fields = this.$optixWS.call(
      "GET",
      "/venues/options/profileFields/",
      {
        params: {
          venue_id: this.$optixConfiguration.venue.venue_id,
        },
      }
    );

    let venue_properties = this.$optixWS.call("GET", "/properties/list/", {
      params: {
        venue_id: this.$optixConfiguration.venue.venue_id,
        reference_type: "venues_members",
      },
    });

    let member_properties = this.$optixWS.call("GET", "/properties/values/", {
      params: {
        venue_id: this.$optixConfiguration.venue.venue_id,
        reference_type: "venues_members",
        reference_id: this.$optixConfiguration.user.lookup.member_account,
        has_permission: 0,
      },
    });

    Promise.all([venue_profile_fields, venue_properties, member_properties])
      .then((success) => {
        this.profile_fields = success[0].data.response;

        // Filter properties, removing private fields and indexing public fields
        success[2].data.response.map((property_group) => {
          let property_fields = [];

          property_group.properties.map((property_field) => {
            if (
              !property_field.is_public &&
              property_field.permissions[0] &&
              property_field.permissions[0].is_allowed == "1" &&
              (this.isInquiryMode
                ? parseInt(property_field.inquiry_display_policy) > 0
                : property_field.display_onboarding == "1")
            ) {
              property_fields.push(property_field);
            }
          });

          if (property_fields.length) {
            this.property_fields[
              property_group.property_group_id
            ] = property_fields;
            this.property_values[property_group.property_group_id] = {}; // Create empty node for values
            this.property_groups.push(property_group);
          }
        });

        // Index current property values
        success[2].data.response.map((property_group) => {
          let property_values = {};
          property_group.properties.map((property_field) => {
            if (property_field.type == "multiple") {
              let values = [];
              property_field.values.map((value) => {
                values.push(value.property_option_id);
              });
              property_values[property_field.property_id] = values;
            } else if (property_field.type == "select") {
              property_values[property_field.property_id] = property_field
                .values[0]
                ? property_field.values[0].property_option_id
                : "";
            } else {
              property_values[property_field.property_id] = property_field
                .values[0]
                ? property_field.values[0].value
                : "";
            }
          });

          this.property_values[
            property_group.property_group_id
          ] = property_values;
        });

        // If no profile fields are required, go ahead to details page
        let possible_options_counter = 0;
        if (
          this.isAvailable("primary_location") &&
          !this.isProfile("primary_location")
        ) {
          possible_options_counter++;
        }
        this.possible_options.map((option) => {
          if (this.isAvailable(option)) {
            possible_options_counter++;
          }
        });
        if (possible_options_counter == 0 && this.property_groups.length == 0) {
          if (this.backAction) {
            this.back();
          } else {
            this.goNextPage();
          }
        }
        this.loadingData = false;
      })
      .catch((error) => {
        this.$optixConfiguration.showSystemError(
          "Error retrieving details",
          error
        );
      });
  },
  watch: {},
  computed: {
    isInquiryMode() {
      return window.location.href.includes("inquiry");
    },
    requiresPaymentMethod() {
      return (
        this.$optixConfiguration.venue.membership.require_payment_method == 1 &&
        this.$optixConfiguration.venue.payment_gateway &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods &&
        this.$optixConfiguration.venue.payment_gateway.payment_methods.length >
          0
      );
    },
    availablePrimaryLocations() {
      if (this.isRequired("primary_location")) {
        return this.$optixConfiguration.venue.locations;
      }
      return [{ location_id: null, name: "None" }].concat(
        this.$optixConfiguration.venue.locations
      );
    },
    backAction() {
      let urlParams = new URLSearchParams(window.location.search);
      return urlParams.get("back") == "true";
    },
  },
  methods: {
    isProfile(field_name) {
      return this.profile_fields.fields.some(
        (field) =>
          field.field == field_name &&
          field.display_onboarding &&
          field.display_profile
      );
    },
    isRequired(field_name) {
      if (this.isInquiryMode) {
        return this.profile_fields.fields.some(
          (field) =>
            field.field == field_name && field.inquiry_display_policy == 2
        );
      }
      return this.profile_fields.fields.some(
        (field) =>
          field.field == field_name &&
          field.display_onboarding &&
          field.is_required
      );
    },
    isAvailable(field_name) {
      if (this.isInquiryMode) {
        return this.profile_fields.fields.some(
          (field) =>
            field.field == field_name && field.inquiry_display_policy !== 0
        );
      }
      return this.profile_fields.fields.some(
        (field) => field.field == field_name && field.display_onboarding
      );
    },
    back() {
      this.$router.push({ name: "Profile", query: { back: true } });
    },
    goNextPage() {
      this.$router.push({ name: "PlanList" });
    },
    next() {
      let extra_promises = [];
      // Trigger validations for property fields
      forEach(this.property_fields, (fields) => {
        fields.map((field) => {
          extra_promises.push(
            this.$refs[`prop${field.property_id}`][0].validateField()
          );
        });
      });

      let validations = {};
      if (this.isRequired("phone")) {
        validations["phone"] = [Validate.rules.phone];
      } else {
        validations["phone"] = [
          Validate.rules.optional([Validate.rules.phone]),
        ];
      }
      if (
        this.isAvailable("primary_location") &&
        !this.isProfile("primary_location")
      ) {
        if (this.isRequired("primary_location")) {
          validations["primary_location_id"] = [Validate.rules.required];
        } else {
          validations["primary_location_id"] = [
            Validate.rules.optional([Validate.rules.required]),
          ];
        }
      }
      this.validateForm(
        {
          ...this.$optixConfiguration.user.data,
          primary_location_id: this.$optixConfiguration.member.data
            .primary_location_id,
        },
        validations,
        extra_promises
      )
        .then((success) => {
          this.nextLoading = true;

          let save_promises = [];
          // Trigger validations for property fields
          forEach(this.property_fields, (fields) => {
            fields.map((field) => {
              save_promises.push(
                this.$refs[`prop${field.property_id}`][0].saveValue()
              );
            });
          });

          let update_promise = this.$optixWS.call(
            "POST",
            "/users/update/",
            {},
            {
              user_id: this.$optixConfiguration.user.data.user_id,
              phone: this.$optixConfiguration.user.data.phone,
            }
          );

          if (
            this.isAvailable("primary_location") &&
            !this.isProfile("primary_location")
          ) {
            let primary_location_promise = this.$optixWS.graphQL(
              `mutation accountUpdate($user_id: ID!, $organization_id: ID!, $primary_location_id: ID, $set_no_primary_location: Boolean ){
              accountUpdate(organization_id: $organization_id, account:{user_id: $user_id,  organization_id: $organization_id } primary_location_id: $primary_location_id set_no_primary_location:$set_no_primary_location){
                account_id
                name
                primary_location {
                  location_id
                  name
                }
              }
            }`,
              {
                user_id: this.$optixConfiguration.user.data.user_id,
                organization_id: this.$optixConfiguration.venue.venue_id,
                ...(this.$optixConfiguration.member.data.primary_location_id > 0
                    ? { primary_location_id: this.$optixConfiguration.member.data.primary_location_id }
                    : { set_no_primary_location: true })
              },
              "accountUpdate"
            );
            save_promises.push(primary_location_promise);
          }

          Promise.all([update_promise].concat(save_promises))
            .then((success) => {
              this.nextLoading = false;
              this.goNextPage();
            })
            .catch((error) => {
              this.nextLoading = false;
              this.$optixConfiguration.showSystemError(
                "Error updating user",
                error
              );
            });
        })
        .catch((e) => {
          this.$refs.form.focusErrors();
        });
    },
  },
};
</script>
