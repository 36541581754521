<script>
export default {
  props: {
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    items: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    dFlex: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    selectItem(item) {
      this.$emit("input", item.value);
    },
    getTextColor() {
      const bgColor = this.$optixConfiguration.options.backgroundColor;
      let r, g, b;

      // Convert HEX or RGB to RGB values
      if (bgColor.startsWith('#')) {
        const hex = bgColor.length === 4
            ? bgColor.slice(1).split('').map(c => c + c).join('')
            : bgColor.slice(1);
        [r, g, b] = [0, 1, 2].map((i) => parseInt(hex.slice(i * 2, i * 2 + 2), 16));
      } else if (bgColor.startsWith('rgb')) {
        [r, g, b] = bgColor.match(/\d+/g).map(Number);
      }

      // Calculate luminance and return color
      return (0.299 * r + 0.587 * g + 0.114 * b) / 255 > 0.5 ? 'black !important' : 'white !important';
    },

  },
};
</script>

<template>
  <div :class="dFlex ? 'd-flex space-x-3' : null">
    <div
        v-for="item in items"
        :key="item.value"
        class="selection-box rounded-md cursor-pointer text-semibold"
        v-bind="$attrs"
        :class="{
        'selection-box--selected bg-blue-light-alt text-blue':
          value === item.value,
        'flex-1': fullWidth,
        disabled: item.disabled,
      }"
        @click="item.disabled ? null : selectItem(item)"
        :style="{color: getTextColor()}"
    >
      <div v-if="item.text" class="d-flex align-center pa-4 rounded-md">
        {{ item.text }}
      </div>
      <slot name="content" v-bind:item="item"></slot>
    </div>
  </div>
</template>

<style scoped>
.selection-box {
  border: 1px solid transparent;
  min-height: 4rem;
  min-width: 8rem;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
}

.selection-box.disabled {
  opacity: 0.4;
}

.selection-box.disabled > div:hover {
  border-color: none;
}

.selection-box > div {
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  height: 100%;
  width: 100%;
  -moz-transition: all 0.15s linear;
  -webkit-transition: all 0.15s linear;
  transition: all 0.15s linear;
  color: inherit !important;
  mix-blend-mode: darken;
}

.selection-box > div:hover {
  border-color: rgb(102, 102, 102);
}

.selection-box.selection-box--selected {
  cursor: default;
}

.selection-box.selection-box--selected,
.selection-box.selection-box--selected > div {
  border-color: #4885ff;
  border-radius: 4px;
}
</style>
